import './Product.scss';
import React, { useState } from 'react';
import IMerchProduct from '../../../../../models/restful/IMerchProduct';
import IMerchProductOrder from '../../../../../models/restful/IMerchProductOrder';
import { api } from '../../../../../api/Api';
import IMerchProductOrderItem from '../../../../../models/restful/IMerchProductOrderItem';

interface IProductsProps {
  product: IMerchProduct;
  order: IMerchProductOrder;
  setOrder(order: IMerchProductOrder): void;
  setIsChecked(product: IMerchProduct, checked: boolean): void;
}

const Product: React.FunctionComponent<IProductsProps> = (
  props: IProductsProps
) => {
  const order = props.order;
  const p = props.product;
  const [checked, setChecked] = useState<boolean>(
    !!order.ProductOrderItems.find(oi => oi.ProductID === p.ID)
  );

  const toggleSelectedProduct = (p: IMerchProduct, b: boolean) => {
    if (b) {
      api
        .createOrderItem({
          ProductID: p.ID,
          OrderID: order.ID
        } as IMerchProductOrderItem)
        .then(resp => {
          setChecked(b);
          props.setIsChecked(props.product, true);
          props.setOrder(resp.item);
        });
    } else {
      api
        .deleteOrderItem({
          ProductID: p.ID,
          OrderID: order.ID
        } as IMerchProductOrderItem)
        .then(resp => {
          setChecked(b);
          props.setIsChecked(props.product, false);
          props.setOrder(resp.item);
        });
    }
  };

  const src = p?._props?.previewImageUrl;
  return (
    <div className="col-2 mb-3">
      <div className="card">
        <span
          style={{
            display: 'inline-block',
            height: '120px',
            backgroundImage: `url("${src}")`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
          onClick={() => toggleSelectedProduct(p, !checked)}
          title={p.Title}
        ></span>
        <div className="card-body" style={{ padding: '7px' }}>
          <h5
            className="card-title"
            style={{ height: '40px', overflow: 'hidden' }}
          >
            {p.Title}
          </h5>
          <p className="card-text" style={{ height: '30px' }}>
            Price: {p._props.properties.FixedPrice.nice}
          </p>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => toggleSelectedProduct(p, !checked)}
          >
            {!checked ? 'Add to cart' : 'Remove'}
          </button>
        </div>
        <input
          type="checkbox"
          className="puduct-check-input"
          checked={checked}
          onChange={() => toggleSelectedProduct(p, !checked)}
        />
      </div>
    </div>
  );
};

export default Product;
